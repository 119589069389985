<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div>
          <h3 class="mb-0">
            Sign Up
          </h3>
          <p>
            Enter your email address to sign up.
          </p>
          <form class="mt-4">
            <div class="form-group">
              <label for="exampleInputEmail2">Email address</label>
              <input v-model="userEmail" type="email" class="form-control mb-0" id="exampleInputEmail2" placeholder="Enter email">
            </div>
            <div class="d-inline-block w-100">
              <button type="button" class="btn btn-primary float-right" @click="register">
                Register
              </button>
            </div>
            <div class="sign-info">
              <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ name: 'auth.login'}">Sign In</router-link></span>
              <ul class="iq-social-media mt-4">
                <li>
                  <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                </li>
              </ul>
            </div>
          </form>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"

export default {
  name: "Register",
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userName: "",
    showPasswordField: false
  }),
  mounted () {
    if (this.userData && this.userData.user_email) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    async register () {
      let userObj = {
        "user_email": this.userEmail
      }
      let userRegisterResp = await User.userRegister(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userRegisterResp)
      if (!userRegisterResp.resp_status) {
        this.toastVariant = "danger"
      }
      else {
        let userProfileData = {
          user_id: userRegisterResp.resp_data.user_id,
          user_email: this.userEmail,
          login_process: "email"
        }
        this.$store.dispatch("User/setUserProfileAction", { ...this.userData, ...userProfileData })
        setTimeout(() => {
          this.$router.push("/auth/activate")
        }, 1000)
      }
    }
  }
}
</script>
